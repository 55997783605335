.container {
  height: 100%;
  display: flex;
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 850px) {
  .container {
    transform: translateX(0);
  }
  .container.open{
    transform: translateX(300px);
  }
  nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
  }
}

/* NAVIGATION */
nav {
  display: flex;
  flex-direction: column;
  background:#ff805c;
  text-align: left;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
  width: 300px;
  box-sizing: border-box;

}

nav button{
  text-align: left;
  font-size: 1rem;
  color: #000000;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: none;
  transition: color 0.3s linear;
  cursor: pointer;
  padding-left: 1.25rem;
  box-sizing: border-box ;
}
nav ul {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
nav ul li {
  padding: .5rem 0;
  box-sizing: border-box;
  border-left: 4px solid transparent;
}
.active {
  border-left: 4px solid #000000;
}
.active button {
  font-weight: bold;
}

nav .logo {
  margin-bottom: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  height: 39px;
}
.reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

button.reset {
  background: transparent;
  border: none;
  text-align: center;
  box-sizing: border-box;
  color: #000000;
  font-size: 1rem;
  text-decoration: underline;
  line-height: 1.375rem;
  margin-top: 1rem;
}

  nav {
  }

/* CONTENT */

.content {
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.overlay {
  display: none;
}

@media (max-width: 850px) {
  .open .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    display: block;
    background: rgba(0,0,0,0.5);
  }
}

form {
  display: flex;
  flex-direction: column;
  flex:1;
}

h1 {
  font-size: 3.125rem;
  line-height: 3.875rem;
  color: #000000;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  color: #000000;
  font-weight: normal;
  padding: 1rem 0;
  margin: 0;
}

h3, .select-label {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: normal;
  color: #000000;
  padding: 1rem 0;
  margin: 0;
}

h4 {
  margin: 0;
  margin-bottom: .75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
}

hr {
  border: none;
  background: #e8e8e8;
  height: 1px;
  margin: 1rem 0 1.25rem 0;
}

p {
  margin: 0;
}

label {
  display: block;
}
.label-tooltip {
  position:relative;
  padding-right: 1.5rem;
}

input[type="text"], select, textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  font-size: 1rem;
  border: 1px solid #e8e8e8;
  color: #606060;
}
input[type="text"],textarea {
  padding: 1rem;
}

select {
  padding: .75rem;
  background: #ffffff url('../common/chevron-down.svg') no-repeat right 0.75rem bottom 50%;
  appearance: none;
}

input[type="checkbox"] + *::before, input[type="radio"] + *::before {
  content: '';
  display: inline-block;
  box-shadow: 0 0 0 1px #e8e8e8;
  width: 17px;
  height: 17px;
  margin-right: 0.5rem;
  box-sizing: border-box;
  position: absolute;
  top: 3px;
  left: 0;
}


input[type="radio"] + *::before {
  border-radius: 50%;
}

input[type="checkbox"]:checked + *::before, input[type="radio"]:checked + *::before {
  border: 3px solid #ffffff;
  background-color: #ff805c;; 
  box-shadow: 0 0 0 1px #ff805c;
}

input[type="checkbox"] + *, input[type="radio"] + * {
  position: relative;
  padding-left: 1.625rem;
  padding-top: 2px;
}

input[type="checkbox"], input[type="radio"] {
  display: none;
}

input[type="checkbox"] + *, input[type="radio"] + * {
  display: block;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline-color: #ff805c;
}

.header {
  background: #ffffff;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header > svg {
  margin-right: 1rem;
  padding: .3rem;
  border: 1px solid #e8e8e8;
}

@media (min-width: 850px) {

  .header {
    display: none;
  }

}

.header h1 {
  font-size: 1rem;
  margin: 0;
}

.section {
  padding: 0 1rem;
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 1rem;
}

.group {
  border: 1px solid #e8e8e8;
  margin-bottom: 1rem;
  background-color: #ffffff;
}

.group-header {
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative
}

.group-header h2 {
  font-weight: bold;
}

.tooltip-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.tooltip {
  opacity: 1 !important;
  font-size: 1rem !important;
  border-radius: 6px !important;
  padding: 1rem !important;
  max-width: 300px;
  box-sizing: border-box;
}
.group-content {
}

.group button {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 100%;
  display: flex;
  align-items: center;
  color: #ff805c;
  font-size: 1rem;
}

.question {
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 0 1rem 1.5rem 1rem;
}

.question:last-of-type {
  border: none;
}

.info {
  background-color: #eaeef3;
  padding: 1rem;
}
.info h2 {
  padding-top: 0;
}

button svg {
  margin-right: 1rem;
}

.fuel {
  position: relative;
  border-top: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 0 1rem 1.5rem 1rem;
}

.delete {
  color: #ff805c;
  position:absolute;
  right: 0;
  top: 0;
  padding: 1rem;
}

.add-fuel {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 1rem 1rem 1rem;
}

.add-fuel-container {
  max-width: 360px;
}

.add-fuel.fuels {
  border-bottom: 1px solid #e8e8e8;
}

.fuels {
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
}

.fuels .fuel:first-of-type {
  border-top: none;
  border-bottom: 1px solid #e8e8e8;
}

.add-solution {
  padding: 0 1rem 1.5rem 1rem;
}

.solution-header {
  position: relative;
}

.solution-header .delete {
  color: #000000;
  padding: 1rem 0;
}

.solution {
  padding: 1rem 1rem 0rem 1rem;
}
.solution p {
  margin-bottom: 1.5rem;
  line-height: 20px;
}

.solution h3 {
  padding-top: 0;
  padding-bottom: 0.5rem;
}

.graph-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  padding: 1.25rem 1rem;

}

.graph-container:last-of-type {
  border: none;
}
.graph {
  max-width: 100%;
}

.group-content.graphs {
  width: 100%;
  padding: 0;
}
.graph-legend {
  list-style-type: none;
  padding: 0;
  background: #f7f7f7;
  padding: 1.25rem;
}
.graph-legend li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: #41414a;
}
.graph-legend li:last-of-type{
  margin-bottom: 0;
}
.legend-icon {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 25px;
}
.graph .test{
  height: 230px;
}
.graph h3 {
  white-space: pre-line;
}
@media (min-width: 850px) {
  .group-content.fuels{
    width: 100%;
  }
}

.help {
  margin-bottom: 2rem;
}

.highlight {
  border: 1px solid #ff805c;
  padding: 1.25rem;
  padding-left: 1.375rem;
}
.highlight h3 {
  margin-top: 0;
}

.note {
  margin-top: 1.5rem;
  background: #fcf7e4;
  padding: 0 1rem 1.5rem 1rem;
  margin-bottom: 1.5rem;
}
.note p {
  margin-bottom: 1.5rem;
  color: #41414a;
}
.note p:last-of-type {
  margin-bottom: 0;
}

.input-group label {
  margin-bottom: 1rem;
}
.input-group > label:last-of-type {
  margin-bottom: 0;
}
.input-group {
}

.input-subgroup {
  margin-left: 1.375rem;
  margin-top: 1rem;
}
.input-group > label:last-of-type + .input-subgroup > label:last-of-type {
  margin-bottom: 0;
}

.highlight .input-group {
  margin-bottom: 0;
}

.toggle-unit-input {
  box-sizing: border-box;
  padding: 1rem 1rem 0rem 1rem;
  position: relative;
}

.toggle-unit-input.open label {
  margin-bottom: 1rem;
}
.toggle-unit-input label span {
  color: #000000;
}
.toggle-unit-input .unit-input {
  margin-left: 1.5rem;
}

.toggle-unit-input:last-of-type {
  padding-bottom: 1.5rem;
}

.unit-input input[type="text"]{
  margin: 0;
}

.unit-input label {
  margin: 1rem 0;
  color: #000000;
  position: relative;
}

.unit-input label .tooltip-icon {
  top: 0;
  right: 0;
}

.unit-input-container {
  position: relative;
}

.unit-input-container.errors input {
  border: 1px solid #ff0000;
}

.unit-text {
  padding: 0 1rem;
}

.unit-text:last-child {
  margin-bottom: 1.5rem;
}

.errors {
  margin-top: 0.5rem;
  color: #ff0000;
}
.unit-input-container .unit {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1rem;
  padding-top: 1rem;
  color: #adadad;
}

.points {
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  padding: 1.5rem 0;
}

.points-outer {
  color:#ffffff;
  width: 229px;
  height: 229px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.points-inner {
  border-radius: 50%;
  width: 190px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.125rem;
  flex-direction: column;
}

.points-text {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.points-text span {
  margin-top: 8px;
  margin-left: 5px;
}

#wellness .points-outer {
  background: rgba(34,207,207,0.2);
}
#wellness .points-inner {
  background: #22cfcf;
}

#health .points-outer {
  background: rgba(255,128,92,0.2); 
}
#health .points-inner  {
  background:#ff805c;
}

#circularEconomy .points-outer {
 background: rgba(38,171,156,0.2); 
}
#circularEconomy .points-inner {
  background:#26ab9c;
}

#competence .points-outer {
 background: rgba(138,21,166,0.2);
}
#competence .points-inner {
  background:#8a15a6;
}

#noResults .points-outer {
  background: rgba(144,144,144,0.2);
}
#noResults .points-inner {
  background: #909090;
}
#noResults p {
  text-align: center;
  padding: 0 1rem 1rem 1rem;
}
#return button.primary {
  justify-content: center;
  margin:0;
}

.accordion {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
}

.accordion-section{
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.accordion-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  box-sizing: border-box;
}

.accordion-content {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
}
.accordion-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.accordion-content p {
  margin-bottom: 1rem;
}
.accordion-content.hidden {
  display: none;
}
.accordion-content li {
  color:#41414a;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2.875rem;
  padding-top: 0.375rem;
}

.accordion-content li::before {
  content: ''; 
  display: inline-block;
  font-size: 1rem; 
  left: 0; 
  position: absolute;
  top: 0em; 
}

#wellness .accordion-content li::before {
  content: url('../common/peukku_turkoosi.svg'); 
}

#health .accordion-content li::before {
  color: #ff805c;
  content: url('../common/peukku_oranssi.svg'); 
}

#circularEconomy .accordion-content li::before {
  content: url('../common/peukku_vihrea.svg'); 
}

#competence .accordion-content li::before {
  content: url('../common/peukku_lila.svg'); 
}

#wellness .accordion-content li.suggestion::before {
  content: url('../common/lamppu_turkoosi.svg'); 
}

#health .accordion-content li.suggestion::before {
  color: #ff805c;
  content: url('../common/lamppu_oranssi.svg'); 
}

#circularEconomy .accordion-content li.suggestion::before {
  content: url('../common/lamppu_vihrea.svg'); 
}

#competence .accordion-content li.suggestion::before {
  content: url('../common/lamppu_lila.svg'); 
}

.form-footer {
  padding: 0 1rem;
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.form-footer button {
  height: 45px;
  text-align: center;
  display: block;
  background: transparent;
  outline:none;
  border: 1px solid #000000;
  width: 100%;
  padding: 0;
  font-size: 1rem;
}

.form-footer button:first-of-type {
  margin-right: 0.5rem;
}

button:disabled {
  background-color: #E5E5E5;
  color: #909090;
  border: 1px solid #e5e5e5;
}

.form-footer .footer-content {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
}

button.primary {
  color: #ffffff;
  background: #ff805c;
  border: 1px solid #ff805c; 
}

@media print {
  .graph-container {
    margin-top: 1rem;
    display: block;
    break-inside: avoid;
  }

  .accordion-section {
    display: block;
  }

  .accordion-header {
    display: block;
  }

  .accordion-content.hidden {
    display: block;
  }
  .accordion-content li {
    break-inside: avoid;
  }

  .accordion-header svg {
    display: none;
  }
  .accordion {
    border-bottom: none;
  }
  .group {
    border: none;
    break-after: always;
    break-inside: avoid;
  }
  .points {
    break-inside: avoid;
  }

  .info {
    display: none;
  }

  .accordion-nobreak {
    break-inside: avoid;
  }

  .logos .group-content {
    display: flex;
    flex-direction: column;
  }

  .logos .group-content img {
    padding-bottom: 1rem;
    align-self: center;
  }

  body {
    background:#ffffff;
  }

  .graph-legend, .legend-icon, .points{
    color-adjust: exact;
  }
  
}

@page {
  size: auto;
  margin: 20mm;
}