@font-face {
  font-family: 'FFDINWebPro';
  src: url('./fonts/FFDINWebPro-regular.eot');
  src: url('./fonts/FFDINWebPro-regular.woff2') format('woff2'),
       url('./fonts/FFDINWebPro-regular.woff') format('woff'),
       url('./fonts/FFDINWebPro-regular.ttf')  format('truetype');
}
@font-face {
  font-family: 'FFDINWebPro';
  src: url('./fonts/FFDINWebPro-bold.eot');
  src: url('./fonts/FFDINWebPro-bold.woff2') format('woff2'),
       url('./fonts/FFDINWebPro-bold.woff') format('woff'),
       url('./fonts/FFDINWebPro-bold.ttf')  format('truetype');
  font-weight: bold;
}

html {
  height: 100%
}
body {
  height: 100%;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
  font-family: FFDINWebPro,Verdana,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  overflow: hidden;
}
input, textarea, button, select {
  font-family: FFDINWebPro,Verdana,Helvetica,sans-serif;
}

#root {
  height: 100%;
  overflow: hidden;
}
