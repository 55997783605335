#results button {
  height: 45px;
  text-align: center;
  display: block;
  background: transparent;
  outline: none;
  border: 1px solid #000000;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

#results button.primary {
  color: #ffffff;
  background: #ff805c;
  border: 1px solid #ff805c;
}

#results .logos .group-content{
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
}
#results .logos .group-content img{ 
  align-self: center;
  max-width: 100%;
  padding-bottom: 1rem;
}

