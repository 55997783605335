.frontpage body{
  background-color: #ffffff;
}

.frontpage .container {
  flex-direction: column;
}
.frontpage-content {
  display: flex;
  flex: 1;
}

.frontpage .frontpage-container, .hero {
  max-width: 485px;
  margin: 0 auto;
  width: 100%;
}
.hero-container, footer, header {
  padding: 0 100px;
}
.hero-container {
  display: flex;
  flex: 1;
  padding-top: 115px;
  padding-bottom: 50px;
}
header {
  max-width: 1920px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .frontpage-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header img {
  height: 39px;
}
.hero p {
  margin-top: 2.1875rem;
}
.hero a {
  display: block;
  padding: 1rem 1.25rem;
  background:#ff805c;
  color: #ffffff;
  max-width: 164px;
  margin-top: 3.75rem;
  text-align: center;
  text-decoration: none;
}
footer {
  background: #000000;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #ffffff;
}

footer a {
  color: #ffffff;
  text-decoration: none;
}
footer ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  margin-top: auto;
  padding: 0;
  margin-bottom: 1.5rem;
}
footer ul li:first-of-type::after {
  content: '|';
  padding: 0 1rem;
}

.cookie {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #000000;
  color: #ffffff;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1.25rem;
}
.cookie h2 {
  color: #ffffff;
  padding-top: 0;
}
.cookie-buttons {
  display: flex;
  justify-content: space-between;
}
.cookie button {
  background:#ffffff;
  border: none;
  padding: 12px 0;
  width: 100%;
  font-size: 1rem;
  color: #000000;
  margin-top: 1.25rem;
}
.cookie button:first-of-type {
  margin-right: 0.5rem;
}

.cookie a {
  color: #ffffff;
  display: block;
  margin-top: 0.3125rem;

}
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 80px;
}
.hero-image img {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: cover;
  object-position: center;
  display: block;
}
.hero-image {
  height: 100%;
}
.side {
  width: 100%;
  height: 100%;
}


@media (min-width: 850px) {
  .frontpage #root {
    overflow: auto;
    overflow-x: hidden;
  }
}
@media (min-width: 850px) and (max-width: 1650px) {
  
}

@media (max-width: 850px) {
  footer ul {
    padding: 0;
  }
  html.frontpage, .frontpage body, .frontpage #root {
    overflow: unset;
  }
  .hero-container, header{
    padding: 0 16px;
  }
  footer {
    padding: 16px;
  }
  .hero-container {
    padding-top: 115px;
    padding-bottom: 50px;
  }
  .main {
    padding-top: 16px;
  }
  .frontpage-content {
    flex-direction: column;
  }
}