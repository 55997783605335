.modal {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.25rem;
  background-color: rgba(0,0,0,0.2);
  z-index: 1000;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.modal .modal-container {
  background-color: #ffffff;
  max-width: 344px;
  width: 100%;
  max-height: 650px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  position:fixed;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (min-width: 850px) {
  .modal .modal-container {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal-content {
  padding: 0 1rem 1rem 1rem;
  flex: 1;
}
.modal label {
  margin: 1rem 0;
  color: #000000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e8e8e8;
  background-color: #ffffff;
  z-index: 1010; /* for some reason fuelmodal content slides on top of the header */
}

.modal-header h2 {
  padding: 0;
}
.modal-header button svg {
  margin: 0;
}
#modal .close {
  outline: none;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding:0;
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.5rem;
}
.modal-footer {
  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  bottom: 0;
  background: #ffffff;

}

#modal .modal-footer button {
  height: 36px;
  box-sizing: border-box;
  width: 118px;
  text-align: center;
  display: block;
  font-size: 1rem;
  background: #ffffff;
  width: 100%;
}

#modal .modal-footer button:first-of-type {
  margin-right: 0.5rem;
}

#modal .modal-footer button.primary {
  color: #ffffff;
  background: #ff805c;
  border: 1px solid #ff805c; 
}

#modal .modal-footer button:disabled {
  background-color: #E5E5E5;
  color: #909090;
  border: 1px solid #e5e5e5;
}

#modal .modal-footer button.cancel{
  color: #ff805c;
  border: 1px solid #ff805c; 
}